<template>
  <el-dialog
    :title="modalType === 'edit' ? '修改押金' : '添加押金设置'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="代理商：" prop="operationId" v-operation>
        <el-select v-model="modalData.operationId" placeholder="代理商名称">
          <el-option
            v-for="(item, index) in agentList.list"
            :label="item.operationName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="押金金额" prop="goodsPrice">
        <el-input
          v-model="modalData.goodsPrice"
          placeholder="请输入押金金额"
          :maxLength="50"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { mapState } from "vuex";

export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),

  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType"],
  data() {
    let operationId = this.$store.getters.userInfo.operationId;
    return {
      operationId: operationId,
      isShowNum: false,
      formName: "form",
      ruleValidate: {
        // operationId: [
        //   {
        //     required: true,
        //     message: "请选择相关代理商",
        //     trigger: "change",
        //   },
        // ],
        goodsMarketPrice: [
          {
            required: true,
            message: "押金金额不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    // 修改使用次数
    changeNum() {
      this.isShowNum = !!this.modalData;
    },

    submit() {
      this.validateForm().then((res) => {
        let obj = {
          operationId:
            this.operationId !== this.const.ADMIN_OPERATION
              ? this.operationId
              : obj.operationId,
          goodsPrice: this.modalData.goodsMarketPrice * 100,
          id: this.modalData.id,
        };
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
